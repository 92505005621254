import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';


@Injectable({
    providedIn: 'root'
})

export class DeviceService {

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService
  ) {}

  getAllDevices(queryParams?: any) {
    const basePath = environment.restBaseUrl + "/device/getAll";
    const path = queryParams ? `${basePath}?${queryParams}` : basePath;

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
        (response: HttpResponse<any>) => {
            const outcome = response['outcome'];
            const dataResponse = response['data'];
            // this.totalItems = dataResponse['total'];
            if (outcome.success === true) {
                this.logger.log("Service:", "SUCCESS", 200);
                return dataResponse;
            } else {
                this.logger.log("Service:", "FAILURE", 200);
                outcome.message = this.settings.manageErrorMsg(outcome);
                return outcome;
            }
        }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
        }
    ));
  }

  requestDevicePairing() {
    const path = environment.restBaseUrl + "/device/request/pairing";
    return this.http
        .post<any[]>( path , null , this.getRequestOptionArgs() )
        .pipe(map(
            (response: HttpResponse<any>) => {
            const res: any = response;

            if (res.outcome.success === true) {
                const dataResponse = res.data;
                this.logger.log("Service:", "SUCCESS", 200);
                return dataResponse;
            } else {
                this.logger.log("Service:", "FAILURE", 200);
                const outcome = res.outcome;
                return outcome;
            }
            }
        ) ,
        catchError((error: HttpErrorResponse) => {
            this.logger.log("Service Fail. Error:", error, 200);
            return throwError(error);
        }
    ));
  }

  unpairDevice(idDevice) {
    const path = environment.restBaseUrl + "/device/" + idDevice + "/unpair";
    return this.http
        .post<any[]>( path , null , this.getRequestOptionArgs() )
        .pipe(map(
            (response: HttpResponse<any>) => {
            const res: any = response;

            if (res.outcome.success === true) {
                const outcome = res.outcome;
                this.logger.log("Service:", "SUCCESS", 200);
                return outcome;
            } else {
                this.logger.log("Service:", "FAILURE", 200);
                const outcome = res.outcome;
                return outcome;
            }
            }
        ) ,
        catchError((error: HttpErrorResponse) => {
            this.logger.log("Service Fail. Error:", error, 200);
            return throwError(error);
        }
    ));
  }

  getRequestOptionArgs(): any {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem("token") || ''
        })
      };
      return httpOptions;
  }

}
