import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { DeviceComponent } from './device/device.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { QrCodeModule } from 'ng-qrcode';

const routes: Routes = [
  { path: '', component: DeviceComponent },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    NgSelectModule,
    ModalModule.forRoot(),
		QrCodeModule
  ],
  providers: [
    BsDropdownDirective
  ],
  declarations: [
    DeviceComponent,
  ],
  exports: [
    RouterModule
  ]
})

export class DevicesModule { }
