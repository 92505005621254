import { Component, OnInit, ViewChild, ViewEncapsulation, TemplateRef } from '@angular/core';
import { AppService } from '../../../core/services/base.service';
import { Router } from '@angular/router';
import { LoggingService } from '../../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { DeviceService } from '../../../services/device.service';
import { SharedService } from '../../../shared/shared.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeviceComponent implements OnInit {
  @ViewChild('dataTable') table: any;
  @ViewChild('table') tableExpandableRows: any;
  @ViewChild('devicePairingModal') devicePairingModal: TemplateRef<any>;
  rows: any[] = [];
  deviceList: any = [];
  sortProp: any;
  expanded: any = {};
  timeout: any;
  selected = [];

  userSelected: any;
  profile : any;

  sortOptions = [
    { "id": 1, "name": "name", "showField":"" },
    { "id": 2, "name": "surname", "showField":"" },
    { "id": 3, "name": "state", "showField":"" },
    { "id": 4, "name": "description", "showField":"" }
  ]
  allStatus: any = [
    { "name": "Attivo" },
    { "name": "Bloccato" },
  ]
  tableRecordNumbers:number;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };

  searchForm: UntypedFormGroup;

  deviceToPair: any = null;

  modalRef : BsModalRef

  constructor(private router: Router,
    public appService: AppService,
    private logService: LoggingService,
    private translate: TranslateService,
    public colors: ColorsService,
    public settings: SettingsService,
    private deviceService: DeviceService,
    public sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private modalService : BsModalService) {
      this.tableRecordNumbers =  parseInt(localStorage.getItem('tableRecordNumbers'));
      var self = this;
      /*this.translate.stream('sort').subscribe(function(obj:any) {
        self.sortOptions[3].showField = obj.profile;
        self.sortOptions[4].showField = obj.date;
      });
      this.translate.stream('sort').subscribe(function(obj:any) {
        self.sortOptions[0].showField = obj.name;
        self.sortOptions[1].showField = obj.surname;
        self.sortOptions[2].showField = obj.state;
      });*/
      // Overrides default total/empty messages
      translate.stream("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res);
      translate.stream("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);

      this.searchForm = this.formBuilder.group({
        name: [null],
        code: [null],
        idMerchant: [null]
      });
  }

  public ngOnInit(): void {
    this.profile = localStorage.getItem('profile')

    this.initIdMerchant();

    this.prepareForSearch();
    
    this.sharedService.sortTableObserver.subscribe((name: any) => {
      this.sortProp = name;
      var that = this;
      setTimeout(function () {
        that.rows = [...that.rows]
      })
    })
  }

  initIdMerchant() {
    this.userSelected = JSON.parse( localStorage.getItem("selectedUser") )

    if (this.userSelected) {
      this.searchForm.controls.idMerchant.setValue(this.userSelected.idMerchant);
      this.searchForm.controls.idMerchant.disable();
    } else {
      this.searchForm.controls.idMerchant.reset();
      this.searchForm.controls.idMerchant.enable();
    }
  }
  
  selectedUser(user) {
    this.initIdMerchant();

    this.prepareForSearch();
  }

  getCapitals() {
    for (var i = 0; i < this.rows.length; i++) {
      var name = this.rows[i].name.split(' ')
      this.rows[i]["capitals"] = name[0].charAt(0) + name[1].charAt(0)
    }
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  toggleExpandRow(row, expanded) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  getAllDevices(queryParams = null) {
    this.deviceService.getAllDevices(queryParams).subscribe((res: any) => {
      this.deviceList = res.results;
      this.rows = this.deviceList;
    })
  }

  searchEvent(event) {
    console.log('searchEvent - event', event);
    let queryParamsSearch = this.getQueryParamsOfSearch(event)
    this.getAllDevices(queryParamsSearch)
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  sortEvent(name) {
    this.sortProp = name;
    var that = this;
    setTimeout(function () {
      that.rows = [...that.rows]
    })
  }

  getQueryParamsOfSearch(searchItems) {
    let queryParamsSearch = "";
    if (searchItems.keyword)
      queryParamsSearch += "keyword=" + searchItems.keyword
    if (searchItems.searchFromSelect.length > 0) {
      if (searchItems.keyword)
        queryParamsSearch += "&"
      queryParamsSearch += "profile=" + searchItems.searchFromSelect.join()
    }
    return queryParamsSearch;
  }

  hideSelectEvent(row) {
    row.statusSelectOpened = false
  }

  prepareForSearch() {
    let queryParamsSearch = "";
    for (let key in this.searchForm.controls) {
      if (this.searchForm.controls[key].value) {
        if (Array.isArray(this.searchForm.controls[key].value)) {
          if (!!this.searchForm.controls[key].value.length) {
            if (queryParamsSearch) {
              queryParamsSearch += "&"
            }
            queryParamsSearch += key + "=" + this.searchForm.controls[key].value.join(',')
          }
        } else {
          if (queryParamsSearch) {
            queryParamsSearch += "&"
          }
          queryParamsSearch += key + "=" + this.searchForm.controls[key].value
        }
      }
    }

    this.getAllDevices(queryParamsSearch)
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  prepareForCancel() {
    this.searchForm.reset();
    this.prepareForSearch();
  }

  async requestDevicePairing() {
    let res = await this.deviceService.requestDevicePairing().toPromise();
    if( res ) {
      this.deviceToPair = res;
    }
  }

  async openModalDevicePairing() {
    await this.requestDevicePairing();
    this.modalRef = this.modalService.show(this.devicePairingModal, { class: 'modal-lg' } )
  }

  unpairDevice(item) {
    swal.fire({
      icon: "warning",
      title: this.translate.instant('device.swal.warning_unpair_title'),
      text: this.translate.instant('device.swal.warning_unpair_text'),
      confirmButtonText: this.translate.instant('device.swal.confirm_unpair_text'),
      confirmButtonColor: "#ed2d2d",
      showCancelButton : true,
      cancelButtonText: this.translate.instant('general.cancel')
    }).then((result) => {
      if (result.value) {
        swal.showLoading();
        this.deviceService.unpairDevice(item.id).subscribe((resp) => {
          if (resp.success) {
            swal.fire( this.translate.instant('swal.title_Success') , this.translate.instant('swal.content_Success') , "success" ).then((value) => {
              this.prepareForSearch();
            })
          }
        })
      } else {
        swal.close()
      }
    })
  }
}

