<div class="wrapper page container">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'device.title' | translate}}</h3>
      <ng-container *ngIf="profile != 'SUPERADMIN' && profile != 'RESELLER'">
        <button type="button" class="btn blue-bg header-button float-right" (click)="openModalDevicePairing()">
          {{'device.device-pairing' | translate}}
        </button>
      </ng-container>
    </div>
  </div>
  <div class="card">
    <div class="w100 app-search">
      <div class="w800">
        <!-- <app-advance-search [page]="locationPage" [sortOptions]="sortOptions" [sortOptionsPlaceholder]="'profile.sort'"
          [selectOptionsPlaceholder]="'profile.selectUsers'" (searchEvent)="searchEvent($event)"
          (sortEvent)="sortEvent($event)"></app-advance-search> -->

        <!--<app-filter-search [page]="'external-application-list'" (searchEvent)="searchEvent($event)"></app-filter-search>-->

        <sp-filter-section [showCancelButton]="searchForm.dirty" (searchClicked)="prepareForSearch()" (cancelClicked)="prepareForCancel()">
          <form [formGroup]="searchForm">
            <div class="row">
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.name" [label]="'general.name'" [placeholder]="'general.name'"></sp-input-text>
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.code" [label]="'general.code'" [placeholder]="'general.code'"></sp-input-text>
              <sp-input-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idMerchant" [label]="'general.merchant'" [placeholder]="'advance-search.select_merchant'"
                *ngIf="(profile=='SUPERADMIN' || profile=='RESELLER') && !selectedUser"></sp-input-merchant-select>
            </div>
          </form>
        </sp-filter-section>

      </div>
    </div>
    <div class="table-margin"><!--  -->
      <ngx-datatable #dataTable class='material outer-table expandable data-table' [columnMode]="'force'" [headerHeight]="0"
        [footerHeight]="70" [rowHeight]="100" [scrollbarH]="true" [scrollbarV]="false"  [rows]='rows' *ngIf="rows.length > 0"
        [limit]="tableRecordNumbers" (page)="onPage($event)" (select)='onSelect($event)' [sorts]="[{prop: sortProp, dir: 'asc'}]" [messages]="my_messages">
        <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <div class="white">
              <div class="col-md-12 inner-panel padding-inner-table">
              </div>
            </div>
          </ng-template>
        </ngx-datatable-row-detail>
        <ngx-datatable-column name="Name" [width]="250">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="row">
              <div class="col-12">
                <h4 class="text-clamp" title="{{row.name}}">{{row.name}}</h4>
                <div class="secondary-text-color">{{row.code}}</div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Merchant" [width]="250" *ngIf="profile=='SUPERADMIN' || profile=='RESELLER'">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="row">
              <div class="col-12">
                <ng-container *ngIf="row.merchant">
                  <h4 class="text-clamp" title="{{row.merchant.companyName}}">{{row.merchant.companyName}}</h4>
                  <div class="secondary-text-color">{{row.merchant.pecAddress}}</div>
                  <div class="secondary-text-color" *ngIf="row.merchant.emailCommunication">{{row.merchant.emailCommunication}}</div>
                </ng-container>
                <ng-container *ngIf="!row.merchant">
                  <h4 class="text-clamp">--</h4>
                </ng-container>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <div class="f-right">
              <button class="blue-bg details-button" (click)="unpairDevice(row)" *ngIf="row.merchant && profile == 'SUPERADMIN'">
                {{'device.unpair_device' | translate}}
              </button>
              <!--<button class="blue-bg details-button" title="Expand/Collapse Row" (click)="toggleExpandRow(row,expanded)">
                <span *ngIf="!expanded">{{'sale.details_button' | translate}}</span>
                <span *ngIf="expanded">{{'general.close' | translate}}</span>
              </button>-->
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <div class="" *ngIf="rows.length == 0">
        <h4 class="a-center">{{ 'statistics.empty_table' | translate }}</h4>
      </div>
    </div>
  </div>  

  <div id="sidebar-wrapper" *ngIf="profile=='SUPERADMIN' || profile=='RESELLER'">
    <app-sidebar-users [users]="users" (selectedUser)="selectedUser($event)"></app-sidebar-users>
  </div>

</div>

<ng-template #devicePairingModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'device.pair_device_title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div>{{ 'device.pair_device_text' | translate }}</div>
        <div class="text-center" *ngIf="deviceToPair">
          <qr-code [value]="deviceToPair.token" [size]="200"></qr-code>
        </div>
      </div>
    </div>
  </div>
</ng-template>
